import React, { Fragment, useContext, useMemo, useState } from "react";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import {
  IsLoadingResponse,
  ToastMessage,
  randomStr,
} from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  SlugUrl,
  ProductName,
  Brand,
  Category,
  MRP,
  SellPrice,
  SKU,
  TAX,
  TAX_PER,
  SEO_Title,
  SEO_Desc,
  SEO_Keyword,
  SortDescription,
  Close,
  ExtraProduct,
  Tag,
  ProductSuggestion,
  LongDescription,
  TEXT_TYPE,
  TextType,
  ATTRIBUTE_TYPE_DROP,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { get_Brand_Master } from "../../../../Apis/brandMasterApi/BrandMasterApi";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { createProductSchema } from "../../../../utils/validationSchema";
import {
  PRODUCT,
  PRODUCT_ADD,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import CKEditors from "react-ckeditor-component";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import ProductsContext from "../../../../context/ProductsContext/ProductsContext";
import {
  Create_Products,
  Get_Check_Slug_Url,
  Get_Details_Products,
  Put_Update_Extra_Products,
  Put_Update_Products,
  Upload_Image,
  get_Products,
} from "../../../../Apis/productsApi/ProductsApi";
import { get_Category_Master } from "../../../../Apis/categoryApi/CategoryApi";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import CropImagePopup from "../../../CropImagePopup";
import { get_Product_Tag_Master } from "../../../../Apis/productTagMasterApi/ProductTagMasterApi";
import { get_Attribute } from "../../../../Apis/attributeApi/AttributeApi";
import { Divider } from "primereact/divider";
import ProductImageUpload from "./ProductImageUpload";
import SelectAttributes from "./SelectAttributes";
import { useDispatch, useSelector } from "react-redux";
import {
  attributeImageUploadOpen,
  selectAttributeOpen,
} from "../../../../redux/slices/selectAttributePopup";
import AttributeImageUploadPopup from "./AttributeImageUploadPopup";
import { SelectedAttribute } from "../../../../hooks";
import { BackButton } from "../../../../reuseableComponents/buttons";

const ProductsCreateFormPopup = (props) => {
  const {
    setFormData,
    id,
    isEdit,
    formData,
    errors,
    setErrors,
    setId,
    setIsEdit,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    dialogModal,
    setdialogsModal,
    subCatIdData,
    setSubCatIdData,
    saveAttData,
    selectAttribute,
    setSelectAttribute,
    setAttSelect,
    attributeImages,
    setAttributeImages,
    attributeMasterId,
    setAttributeMasterId,
    attributeMasterIndex,
    setAttributeMasterIndex,
    setSaveAttData,
    isImageUpload,
    setIsImageUpload,
  } = useContext(ProductsContext);
  const history = useNavigate();
  const [index, setIndex] = useState(0);
  const [filterDrop, setFilterDrop] = useState({
    brandFilter: "",
    categoryFilter: "",
    productFilter: "",
    tagFilter: "",
    categoryAttributeMapFilter: "",
  });
  /**redux */
  const open = useSelector((state) => state.select_attribute_popup);
  const dispatch = useDispatch();

  const [isAttribute, setIsAttribute] = useState(false);
  const [indexAttributeImage, setIndexAttributeImage] = useState(0);
  const [categoryAttributeDataDrop, setCategoryAttributeDataDrop] = useState(
    []
  );
  let { productId } = useParams();

  const emptyData = () => {
    setFormData((prev) => {
      return {
        ...prev,
        productName: "",
        sku: "",
        sortDesc: "",
        longDesc: "",
        mrp: "",
        sellPrice: "",
        catId: "",
        slugUrl: "",
        seoTitle: "",
        seoDesc: "",
        seoKeyword: "",
        brandId: "",
        isReturn: false,
        tax: "",
        taxType: "",
        taxPer: 0.0,
        prodType: "simple",
        tags: "",
        images: [
          {
            id: randomStr(2),
            imgAltText: "",
            imagePath: "",
            order: 1,
            isDefault: false,
          },
        ],
        attributes: [
          {
            id: randomStr(2),
            attributeId: [],
            attributeArr: "",
            attributeType: "",
            mrp: "",
            sellPrice: "",
            taxAmt: "",
            taxType: "",
            taxPer: "",
            isDefault: true,
          },
        ],
        logo: "",
        type: "",
        extraProductIds: "",
        referenceProductIds: "",
        subCat: "",
        subToSubCat: "",
      };
    });
    setSubCatIdData({
      subCat: [],
      subToSub: [],
    });
    setErrors({});
    setSelectAttribute([]);
    setAttributeImages({});
    setAttributeMasterId("");
    setAttributeMasterIndex("");
    setSaveAttData(false);
    setFilterDrop((prev) => {
      return {
        ...prev,
        brandFilter: "",
        categoryFilter: "",
        productFilter: "",
      };
    });
  };

  /**
   *
   * @param {event } e
   * create brand master & update brand master
   */

  const { mutate: createProducts, isLoading: createProductsLoader } =
    useMutation((formData) => Create_Products(formData, history, emptyData));

  const { mutate: updateProducts, isLoading: updateProductsLoader } =
    useMutation((formData) =>
      Put_Update_Products(formData, id, history, emptyData)
    );

  const { mutate: updateExtraProducts } = useMutation((formData) =>
    Put_Update_Extra_Products(formData, id)
  );

  const handleProductsCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createProductSchema();
      await schema.validate(formData, { abortEarly: false });
      if (productId !== "add" && productId !== -1 && isEdit === false) {
        let initialAttributesArray;
        if (formData?.attributes?.length) {
          initialAttributesArray = [...formData?.attributes];
          initialAttributesArray[0].isDefault = true;
        }
        let newData = {
          ...formData,
          brandId: formData?.brandId?.code,
          tags: formData?.tags?.map((elem) => elem.code),
          taxType: formData?.taxType ? formData?.taxType?.code : null,
          attributes:
            formData.prodType === "attribute"
              ? formData.attributes.map((elem, index) => {
                  const elemCopy = { ...elem };
                  if (elemCopy.attributeId) {
                    delete elemCopy.attributeId; // Remove attributeId from the copy
                    delete elemCopy.attributeArr;
                  }
                  return {
                    ...elemCopy,
                    attributeIds: elem.attributeId.map((attIdElem) => {
                      if (
                        attIdElem.attributeType === ATTRIBUTE_TYPE_DROP[2].code
                      ) {
                        let initialAttributeImages =
                          attributeImages[index][attIdElem.attributeMasterId];
                        initialAttributeImages[0].isDefault = true;
                        return {
                          attributeMasterId: attIdElem.attributeMasterId,
                          attributeType: attIdElem.attributeType,
                          attributeId: attIdElem.code,
                          images: initialAttributeImages.map((imagesElem) => {
                            return {
                              ...imagesElem,
                              imagePath: imagesElem?.imagePath
                                .split(process.env.REACT_APP_FILE_FOLDER)
                                .pop(),
                            };
                          }),
                        };
                      } else {
                        return {
                          attributeMasterId: attIdElem.attributeMasterId,
                          attributeType: attIdElem.attributeType,
                          attributeId: attIdElem.code,
                        };
                      }
                    }),
                    taxType: elem.taxType?.code,
                  };
                })
              : [],
        };
        let newDataForExtraProduct = {
          productId,
          type: formData?.type ? formData?.type?.code : null,
          extraProductIds: formData?.extraProductIds?.length
            ? formData?.extraProductIds?.map((elem, i) => {
                return {
                  extraProductId: elem?.code,
                  order: i + 1,
                  type: formData?.type ? formData?.type?.code : null,
                };
              })
            : null,
        };
        if (newDataForExtraProduct?.extraProductIds?.length) {
          await updateExtraProducts(newDataForExtraProduct);
        }
        if (formData.subToSubCat && formData.subToSubCat?.code !== "") {
          newData.catId = formData?.subToSubCat?.code;
        } else if (formData.subCat && formData.subCat?.code !== "") {
          newData.catId = formData?.subCat?.code;
        } else if (formData.catId && formData.catId?.code !== "") {
          newData.catId = formData?.catId?.code;
        }
        updateProducts(newData);
      } else {
        const initialImagesArray = [...formData.images];
        initialImagesArray[0].isDefault = true;
        // const initialAttributesArray = [...formData.attributes];
        // initialAttributesArray[0].isDefault = true;
        let newData = {
          ...formData,
          brandId: formData?.brandId?.code,
          images: initialImagesArray?.map((elem) => {
            return {
              ...elem,
              imagePath: elem?.imagePath
                ? elem?.imagePath.split(process.env.REACT_APP_FILE_FOLDER).pop()
                : [],
            };
          }),
          type: formData?.type ? formData?.type?.code : null,
          taxType: formData?.taxType ? formData?.taxType?.code : null,
          tags: formData?.tags?.length
            ? formData?.tags?.map((elem) => elem.code)
            : [],
          extraProductIds: formData?.extraProductIds?.length
            ? formData?.extraProductIds?.map((elem, i) => {
                return {
                  extraProductId: elem.code,
                  order: i + 1,
                  type: formData?.type ? formData?.type?.code : null,
                };
              })
            : null,
          attributes:
            formData.prodType === "attribute"
              ? formData.attributes.map((elem, index) => {
                  const elemCopy = { ...elem };
                  if (elemCopy.attributeId) {
                    delete elemCopy.attributeId; // Remove attributeId from the copy
                    delete elemCopy.attributeArr;
                  }
                  return {
                    ...elemCopy,
                    attributeIds: elem.attributeId.map((attIdElem) => {
                      if (
                        attIdElem.attributeType === ATTRIBUTE_TYPE_DROP[2].code
                      ) {
                        let initialAttributeImages =
                          attributeImages[index][attIdElem.attributeMasterId];
                        initialAttributeImages[0].isDefault = true;
                        return {
                          attributeMasterId: attIdElem.attributeMasterId,
                          attributeType: attIdElem.attributeType,
                          attributeId: attIdElem.code,
                          images: initialAttributeImages.map((imagesElem) => {
                            return {
                              ...imagesElem,
                              imagePath: imagesElem?.imagePath
                                .split(process.env.REACT_APP_FILE_FOLDER)
                                .pop(),
                            };
                          }),
                        };
                      } else {
                        return {
                          attributeMasterId: attIdElem.attributeMasterId,
                          attributeType: attIdElem.attributeType,
                          attributeId: attIdElem.code,
                        };
                      }
                    }),
                    taxType: elem.taxType?.code,
                  };
                })
              : [],
        };
        if (formData.subToSubCat && formData.subToSubCat?.code !== "") {
          newData.catId = formData?.subToSubCat?.code;
        } else if (formData.subCat && formData.subCat?.code !== "") {
          newData.catId = formData?.subCat?.code;
        } else if (formData.catId && formData.catId?.code !== "") {
          newData.catId = formData?.catId?.code;
        }
        const { logo, ...updatedValue } = newData;
        createProducts(updatedValue);
      }
    } catch (error) {
      const validationErrors = {};
      error &&
        error?.inner &&
        error?.inner?.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });

      setErrors(validationErrors);

      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeOrgAdmin = (e) => {
    const { name, value } = e.target;
    if (name === "subCat") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      let [subCategory] = categoryMasterDrop?.payload?.data
        ?.find((elem) => elem?.catId === formData?.catId?.code)
        ?.subCategories?.filter((elem) => elem?.catId === value?.code);
      setSubCatIdData((prev) => {
        return {
          ...prev,
          subToSub: subCategory,
        };
      });
    } else if (name === "catId") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      let [subCategory] = categoryMasterDrop?.payload?.data?.filter(
        (elem) => elem?.catId === value?.code
      );
      setSubCatIdData({ subCat: subCategory?.subCategories });
    } else if (name === "productName") {
      setFormData((prev) => {
        const updatedData = {
          ...prev,
          [name]: value,
          slugUrl: value.replace(/\s+/g, "-").toLowerCase(),
        };
        if (isEdit) {
          updatedData.images = prev.images.map((elem) => ({
            ...elem,
            imgAltText: value,
          }));
          updatedData.seoTitle = value;
          updatedData.seoDesc = value;
          updatedData.seoKeyword = value;
        }
        return updatedData;
      });
    } else if (name === "slugUrl") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value.replace(/\s+/g, "-").toLowerCase(),
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  /**
   * if productId is available to  refresh page to field fill api call
   */
  const { mutate: getProductDetails, isLoading: getProductDetailsLoader } =
    useMutation(
      (productId) =>
        Get_Details_Products(
          productId,
          history,
          setFormData,
          setSubCatIdData,
          setSelectAttribute,
          setSaveAttData
        ),
      {
        onSuccess: (response) => {
          if (response.status === 200) {
            setId(productId);
            setIsEdit(false);
          }
        },
      }
    );

  useMemo(() => {
    let valueOfNumber = Number(productId);
    if (
      productId !== "add" &&
      typeof valueOfNumber === "number" &&
      formData.productName === ""
    ) {
      getProductDetails(productId);
    } else if (productId === "add") {
      emptyData();
    }
    // eslint-disable-next-line
  }, [productId]);

  /**
   * check slug url
   */
  const { mutate: checkBrandMasterSlug } = useMutation((newData) =>
    Get_Check_Slug_Url(newData)
  );

  const handleFocusChangeSlug = async () => {
    if (formData.slugUrl) {
      let newData = { slugUrl: formData.slugUrl };
      checkBrandMasterSlug(newData);
    }
  };

  /**
   * change editor value
   * @param {value} evt
   */
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setFormData((prev) => {
      return {
        ...prev,
        longDesc: newContent,
      };
    });
  };

  /**
   * get brand and category dropdown data api call
   */

  let paramsObj = {
    showAll: true,
    search: { brandName: filterDrop.brandFilter },
  };
  let { data } = useQuery(
    ["getBrandMasterDrop", paramsObj],
    async () => await get_Brand_Master(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  let paramsCategoryObj = {
    showAll: true,
    search: { brandName: filterDrop.categoryFilter },
  };
  let { data: categoryMasterDrop } = useQuery(
    ["getCategoryMasterDrop", paramsCategoryObj],
    async () => await get_Category_Master(paramsCategoryObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  /**
   * go to parent page
   */
  const redirect = () => {
    history(REDIRECT_PATH.PRODUCTS);
    emptyData();
  };

  /**
   * add and remove extra field
   */
  const handleAddMore = ({
    isCondition = false,
    isImages = false,
    attributeMasterId = 0,
  }) => {
    if (isCondition && isImages && attributeMasterId) {
      setAttributeImages((prev) => {
        return {
          ...prev,
          [attributeMasterIndex]: {
            [attributeMasterId]: [
              ...prev[attributeMasterIndex][attributeMasterId],
              {
                id: randomStr(2),
                imagePath: "",
                isDefault: false,
              },
            ],
          },
        };
      });
    } else if (isCondition) {
      setFormData((prev) => {
        return {
          ...prev,
          attributes: [
            ...prev.attributes,
            {
              id: randomStr(2),
              attributeId: prev.attributes[0]?.attributeId.map((elem) => {
                return {
                  attributeMasterId: elem?.attributeMasterId,
                };
              }),
              attributeArr: "",
              attributeType: "",
              mrp: "",
              sellPrice: "",
              taxAmt: "",
              taxType: "",
              taxPer: "",
              isDefault: false,
            },
          ],
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          images: [
            ...prev.images,
            {
              id: randomStr(2),
              imgAltText: prev.productName,
              imagePath: "",
              order: prev.images.length + 1,
              isDefault: false,
            },
          ],
        };
      });
    }
  };

  const handleRemove = ({
    id,
    isCondition,
    isImages,
    attributeMasterId,
    attributeMasterIndex,
  }) => {
    if (isImages) {
      setAttributeImages((prev) => {
        return {
          ...prev,
          [attributeMasterIndex]: {
            [attributeMasterId]: prev[attributeMasterIndex][
              attributeMasterId
            ].filter((item) => item.id !== id),
          },
        };
      });
    } else if (isCondition) {
      setFormData((prev) => {
        return {
          ...prev,
          attributes: prev.attributes.filter((item) => item.id !== id),
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          images: prev.images.filter((item) => item.id !== id),
        };
      });
    }
  };

  /**
   * skill input value change and input value change
   * @param {event} e
   * @param {i} i
   */
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    let old = [...formData.images];

    old[i][name] = value;
    setFormData({ ...formData, images: old });
  };

  /**
   * image upload function with validation
   */
  const allowedExtensions = ["jpg", "jpeg", "png"];
  //indexAttribute ->> main index
  //i ->> image array index
  const openImageUploadModal = (
    e,
    i,
    isImages,
    attributeMasterId,
    attributeMasterIndex
  ) => {
    const file = e.target.files[0];
    const extension = file?.name?.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(extension)) {
      if (e.target.files && e.target.files.length > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          if (isImages) {
            let old = [
              ...attributeImages[attributeMasterIndex][attributeMasterId],
            ];
            old[i]["imagePath"] = reader.result;
            setFormData({
              ...formData,
              logo: reader.result,
            });
            setAttributeImages((prev) => {
              return {
                ...prev,
                [attributeMasterIndex]: { [attributeMasterId]: old },
              };
            });
            setIndexAttributeImage(i);
            setIsAttribute(true);
            setIndex(attributeMasterId);
            setAttributeMasterId(attributeMasterId);
            setAttributeMasterIndex(attributeMasterIndex);
          } else {
            let old = [...formData.images];
            old[i]["imagePath"] = reader.result;
            setFormData({ ...formData, logo: reader.result, images: old });
            setIndex(i);
            setIndexAttributeImage("");
            setIsAttribute(false);
          }
        };
        setdialogsModal(true);
      }
    } else {
      ToastMessage(300, "file type required only .jpg , .png , .jpeg");
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(
      file,
      setdialogsModal,
      setFormData,
      index,
      isEdit,
      productId,
      formData,
      indexAttributeImage,
      isAttribute,
      setAttributeImages,
      attributeMasterId,
      attributeMasterIndex,
      setIsImageUpload
    )
  );

  /**
   * extra product dropdown
   */

  let paramsProductObj = {
    showAll: true,
  };
  if (filterDrop.productFilter?.length) {
    paramsProductObj["search"] = { productName: filterDrop.productFilter };
  }

  let { data: getExtraProduct } = useQuery(
    ["getProductsDrop", paramsProductObj],
    async () => await get_Products(paramsProductObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  /**
   * products tag dropdown
   */

  let paramsProductTagObj = {
    showAll: true,
    search: { tagName: filterDrop.tagFilter, isActive: true },
  };

  let { data: productTagMasterDrop } = useQuery(
    ["getProductTagMasterDrop", paramsProductTagObj],
    async () => await get_Product_Tag_Master(paramsProductTagObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  /**
   * category attribute map dropdown
   */

  let paramsCategoryAttributeMapObj = {
    showAll: true,
    search: { isActive: true },
  };

  if (filterDrop.categoryAttributeMapFilter.length) {
    paramsCategoryAttributeMapObj.search = {
      ...paramsCategoryAttributeMapObj.search,
      name: filterDrop.categoryAttributeMapFilter,
    };
  }

  const handleChangeVariable = async (i, e, index) => {
    const { name, value } = e.target;
    if (name === "attributeId") {
      setAttSelect((prev) => {
        return { ...prev, [i]: value };
      });
      let param = [...formData.attributes];
      param[index][name] = formData.attributes[index].attributeId.map(
        (elem) => {
          if (elem.attributeMasterId === i && value?.attributeType === 3) {
            return {
              attributeMasterId: i,
              code: value?.code,
              name: value?.name,
              attributeType: value?.attributeType,
            };
          } else if (elem.attributeMasterId === i) {
            return {
              attributeMasterId: i,
              code: value?.code,
              name: value?.name,
              attributeType: value?.attributeType,
            };
          } else {
            return elem;
          }
        }
      );
      setFormData((prev) => {
        return {
          ...prev,
          attributes: param,
        };
      });
    } else {
      let param = [...formData.attributes];
      param[i][name] = value;
      if (name === "attributeMasterId") {
        setCategoryAttributeDataDrop(
          categoryAttributeDataDrop.filter(
            (elem) => elem.attributeMasterId !== value?.code
          )
        );
        let paramObj = {
          showAll: true,
          search: { attributeMasterId: value?.code },
        };
        let response = await get_Attribute(paramObj);
        if (value?.code) {
          param[i]["attributeArr"] = response?.payload?.data;
        } else {
          param[i]["attributeArr"] = [];
        }
      } else if (name === "attributeId") {
        param[i]["attributeType"] = value?.length ? value[0]?.type : "";
      }
      setFormData((prev) => {
        return {
          ...prev,
          attributes: param,
        };
      });
    }
  };

  const handleOpenAttributeImagePopup = ({ attributeMasterId, index }) => {
    if (
      Object.keys(attributeImages).length > 0 &&
      attributeImages[index] &&
      attributeImages[index][attributeMasterId]
    ) {
      dispatch(attributeImageUploadOpen());
    } else {
      dispatch(attributeImageUploadOpen());
      setAttributeImages((prev) => {
        return {
          ...prev,
          [index]: {
            [attributeMasterId]: [
              {
                id: randomStr(2),
                imagePath: "",
                isDefault: true,
              },
            ],
          },
        };
      });
    }
    setAttributeMasterId(attributeMasterId);
    setAttributeMasterIndex(index);
  };

  const clearDropdown = (value) => {
    for (const key in formData?.attributes) {
      for (const i of formData?.attributes[key].attributeId) {
        if (value.code === i.code) {
          let newValue = formData?.attributes[key].attributeId.map((elem) => {
            if (elem.attributeMasterId === i.attributeMasterId) {
              return {
                attributeMasterId: i.attributeMasterId,
              };
            } else {
              return elem;
            }
          });
          let NewForm = [...formData.attributes];
          NewForm[key]["attributeId"] = newValue;
          setFormData({
            ...formData,
            attributes: NewForm,
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_ADD} />
      <IsLoadingResponse
        isLoading={
          createProductsLoader ||
          updateProductsLoader ||
          getProductDetailsLoader ||
          isImageUpload
        }
      />
      <BackButton
        history={history}
        path={REDIRECT_PATH.PRODUCTS}
        otherFunc={emptyData}
      />
      <div className="card-div">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleProductsCreateAndUpdate(e)}
                >
                  <Row
                    className=" mb-5"
                    style={{
                      background: "#00000008",
                      padding: "14px 14px 14px 1px",
                    }}
                  >
                    {formData?.images?.map((elem, i) => {
                      return (
                        <Col md="3 mb-3" key={i}>
                          <ProductImageUpload
                            onChange={openImageUploadModal}
                            index={i}
                            image={elem?.imagePath}
                            imgAltText={elem.imgAltText}
                            isEdit={isEdit}
                            length={formData?.images.length}
                            id={elem.id}
                            handleRemove={handleRemove}
                            handleChange={handleChange}
                            images={false}
                          />
                        </Col>
                      );
                    })}

                    <div className="btn-style gap-3 justify-content-center ">
                      <Button
                        label="Add More"
                        icon="pi pi-plus"
                        severity="warning"
                        onClick={() => handleAddMore({ isCondition: false })}
                        type={TYPE_FELDS.button}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={ProductName}
                        name="productName"
                        id="productName"
                        type={TYPE_FELDS.text}
                        placeholder={ProductName}
                        value={formData.productName}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.productName && (
                        <span className="error-validation">
                          {errors.productName}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Brand}
                        name="brandId"
                        id="brandId"
                        placeholder={Brand}
                        value={formData.brandId}
                        handleChange={handleChangeOrgAdmin}
                        dropdown={true}
                        option={data?.payload?.data?.map((elem) => {
                          return {
                            code: elem?.brandId,
                            name: elem?.brandName,
                          };
                        })}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              brandFilter: e.target.value,
                            };
                          })
                        }
                      />
                      {errors.brandId && (
                        <span className="error-validation">
                          {errors.brandId}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={`${Category} *`}
                        name="catId"
                        id="catId"
                        placeholder={`${Category} *`}
                        value={formData.catId}
                        handleChange={(e) => {
                          handleChangeOrgAdmin(e);
                        }}
                        dropdown={true}
                        option={categoryMasterDrop?.payload?.data?.map(
                          (elem) => {
                            return {
                              code: elem.catId,
                              name: elem.name,
                            };
                          }
                        )}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              categoryFilter: e.target.value,
                            };
                          })
                        }
                      />
                      {errors.catId && (
                        <span className="error-validation">{errors.catId}</span>
                      )}
                    </Col>
                    {subCatIdData?.subCat?.length &&
                    subCatIdData?.subCat &&
                    subCatIdData?.subCat !== "" ? (
                      <Col md="6 mb-4">
                        <FormDataFeild
                          label={`Sub ${Category} *`}
                          name="subCat"
                          id="subCat"
                          placeholder={`Sub ${Category} *`}
                          value={formData.subCat}
                          handleChange={(e) => {
                            handleChangeOrgAdmin(e);
                          }}
                          dropdown={true}
                          option={
                            subCatIdData?.length
                              ? subCatIdData?.subCat?.map((elem) => {
                                  return {
                                    code: elem.catId,
                                    name: elem.name,
                                  };
                                })
                              : []
                          }
                          search={(e) =>
                            setFilterDrop((prev) => {
                              return {
                                ...prev,
                                categoryFilter: e.target.value,
                              };
                            })
                          }
                        />
                        {errors.catId && (
                          <span className="error-validation">
                            {errors.catId}
                          </span>
                        )}
                      </Col>
                    ) : (
                      ""
                    )}
                    {subCatIdData?.subToSub?.subCategories?.length &&
                    subCatIdData?.subToSub !== "" ? (
                      <Col md="6 mb-4">
                        <FormDataFeild
                          label={`Sub to sub ${Category} *`}
                          name="subToSubCat"
                          id="subToSubCat"
                          placeholder={`Sub to sub ${Category} *`}
                          value={formData.subToSubCat}
                          handleChange={(e) => {
                            handleChangeOrgAdmin(e);
                          }}
                          dropdown={true}
                          option={subCatIdData?.subToSub?.subCategories?.map(
                            (elem) => {
                              return {
                                code: elem.catId,
                                name: elem.name,
                              };
                            }
                          )}
                          search={(e) =>
                            setFilterDrop((prev) => {
                              return {
                                ...prev,
                                categoryFilter: e.target.value,
                              };
                            })
                          }
                        />
                        {errors.catId && (
                          <span className="error-validation">
                            {errors.catId}
                          </span>
                        )}
                      </Col>
                    ) : null}

                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={SlugUrl}
                        name="slugUrl"
                        id="slugUrl"
                        type={TYPE_FELDS.text}
                        placeholder={SlugUrl}
                        value={formData.slugUrl}
                        handleChange={handleChangeOrgAdmin}
                        handleFocusChange={handleFocusChangeSlug}
                        blur={true}
                      />
                      {errors.slugUrl && (
                        <span className="error-validation">
                          {errors.slugUrl}
                        </span>
                      )}
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={Tag}
                        name="tags"
                        id="tags"
                        placeholder={Tag}
                        value={formData.tags}
                        handleChange={handleChangeOrgAdmin}
                        multiDropdown={true}
                        multiOption={productTagMasterDrop?.payload?.data?.map(
                          (elem) => {
                            return { code: elem.tagName, name: elem.tagName };
                          }
                        )}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              tagFilter: e.target.value,
                            };
                          })
                        }
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={ExtraProduct}
                        name="extraProductIds"
                        id="extraProductIds"
                        placeholder={ExtraProduct}
                        value={formData.extraProductIds}
                        handleChange={handleChangeOrgAdmin}
                        multiDropdown={true}
                        multiOption={getExtraProduct?.payload?.data?.map(
                          (elem) => {
                            return {
                              name: elem?.productName,
                              code: elem?.productId,
                            };
                          }
                        )}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              productFilter: e.target.value,
                            };
                          })
                        }
                      />
                    </Col>
                    <Col md="4 mb-4">
                      <FormDataFeild
                        label={ProductSuggestion}
                        name="referenceProductIds"
                        id="referenceProductIds"
                        placeholder={ProductSuggestion}
                        value={formData.referenceProductIds}
                        handleChange={handleChangeOrgAdmin}
                        multiDropdown={true}
                        multiOption={getExtraProduct?.payload?.data?.map(
                          (elem) => {
                            return {
                              name: elem?.productName,
                              code: elem?.productId,
                            };
                          }
                        )}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              productFilter: e.target.value,
                            };
                          })
                        }
                      />
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={MRP}
                        name="mrp"
                        id="mrp"
                        type={TYPE_FELDS.number}
                        placeholder={MRP}
                        value={formData.mrp}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.mrp && (
                        <span className="error-validation">{errors.mrp}</span>
                      )}
                    </Col>
                    <Col md="2 mb-4">
                      <FormDataFeild
                        label={SellPrice}
                        name="sellPrice"
                        id="sellPrice"
                        type={TYPE_FELDS.number}
                        placeholder={SellPrice}
                        value={formData.sellPrice}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.sellPrice && (
                        <span className="error-validation">
                          {errors.sellPrice}
                        </span>
                      )}
                    </Col>
                    <Col md="2 mb-4">
                      <FormDataFeild
                        label={TAX_PER}
                        name="taxPer"
                        id="taxPer"
                        type={TYPE_FELDS.number}
                        placeholder={TAX_PER}
                        value={formData.taxPer}
                        handleChange={handleChangeOrgAdmin}
                      />
                    </Col>
                    <Col md="2 mb-4">
                      <FormDataFeild
                        label={SKU}
                        name="sku"
                        id="sku"
                        type={TYPE_FELDS.text}
                        placeholder={SKU}
                        value={formData.sku}
                        handleChange={handleChangeOrgAdmin}
                      />
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={TextType}
                        name="taxType"
                        id="taxType"
                        placeholder={TextType}
                        value={formData.taxType}
                        handleChange={handleChangeOrgAdmin}
                        dropdown={true}
                        option={TEXT_TYPE}
                        filter={false}
                      />
                      {errors.taxType && (
                        <span className="error-validation">
                          {errors.taxType}
                        </span>
                      )}
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={TAX}
                        name="tax"
                        id="tax"
                        type={TYPE_FELDS.text}
                        placeholder={TAX}
                        value={formData.tax}
                        handleChange={handleChangeOrgAdmin}
                        disabled={true}
                      />
                    </Col>

                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={SEO_Title}
                        name="seoTitle"
                        id="seoTitle"
                        type={TYPE_FELDS.text}
                        placeholder={SEO_Title}
                        value={formData.seoTitle}
                        handleChange={handleChangeOrgAdmin}
                        disabled={isEdit === false && true}
                      />
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={SEO_Desc}
                        name="seoDesc"
                        id="seoDesc"
                        type={TYPE_FELDS.text}
                        placeholder={SEO_Desc}
                        value={formData.seoDesc}
                        handleChange={handleChangeOrgAdmin}
                        disabled={isEdit === false && true}
                      />
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={SEO_Keyword}
                        name="seoKeyword"
                        id="seoKeyword"
                        type={TYPE_FELDS.text}
                        placeholder={SEO_Keyword}
                        value={formData.seoKeyword}
                        handleChange={handleChangeOrgAdmin}
                        disabled={isEdit === false && true}
                      />
                    </Col>
                    <Col md="6 mb-4">
                      <div className="flex flex-column gap-2 padding">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Return"}
                        </label>
                        <InputSwitch
                          checked={formData.isReturn}
                          onChange={(e) => handleChangeOrgAdmin(e)}
                          name="isReturn"
                          id="isReturn"
                        />
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <div className="flex flex-column gap-2 padding">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {"Product Type"}
                        </label>
                        <div className="flex flex-wrap justify-content-between ">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="simple"
                              name="prodType"
                              value="simple"
                              onChange={(e) => handleChangeOrgAdmin(e)}
                              checked={formData.prodType === "simple"}
                            />
                            <label htmlFor="simple" className="ml-2">
                              Simple
                            </label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="attribute"
                              name="prodType"
                              value="attribute"
                              onChange={(e) => {
                                handleChangeOrgAdmin(e);
                                dispatch(selectAttributeOpen());
                              }}
                              checked={formData.prodType === "attribute"}
                            />
                            <label htmlFor="attribute" className="ml-2">
                              Attribute
                            </label>
                          </div>
                          {formData.prodType === "attribute" ? (
                            <div>
                              <Button
                                label="Update Mapping"
                                severity="help"
                                text
                                type="button"
                                style={{ height: "53px" }}
                                onClick={() => {
                                  dispatch(selectAttributeOpen());
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ opacity: "0" }}>.</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    {formData.prodType === "attribute" && saveAttData && (
                      <Row
                        style={{
                          background: "#00000008",
                          padding: "14px 1px 0px 1px",
                          marginBottom: "14px",
                        }}
                      >
                        <div className="btn-style gap-3 justify-content-end pb-3 ">
                          <Button
                            label="Add More"
                            icon="pi pi-plus"
                            severity="warning"
                            onClick={() => handleAddMore({ isCondition: true })}
                            type={TYPE_FELDS.button}
                          />
                        </div>
                        {formData?.attributes?.map((elem, i) => {
                          return (
                            <>
                              <Row>
                                <Divider align="left">
                                  <div className="inline-flex align-items-center gap-3 ">
                                    <b>{i + 1}</b>
                                    {formData.attributes.length > 1 && (
                                      <Button
                                        label=""
                                        icon="pi pi-trash"
                                        severity="danger"
                                        onClick={() =>
                                          handleRemove({
                                            id: elem.id,
                                            isCondition: true,
                                          })
                                        }
                                        type={TYPE_FELDS.button}
                                      />
                                    )}
                                  </div>
                                </Divider>

                                {selectAttribute.map((selectElem) => {
                                  return (
                                    <Col
                                      md="4 mb-4"
                                      className="d-flex align-items-center gap-3 "
                                    >
                                      <FormDataFeild
                                        label={selectElem?.categoryAtt?.name}
                                        name="attributeId"
                                        id="attributeId"
                                        placeholder={
                                          selectElem?.categoryAtt?.name
                                        }
                                        value={
                                          elem?.attributeId?.length
                                            ? SelectedAttribute({
                                                elem,
                                                selectElem,
                                              })
                                            : {}
                                        }
                                        handleChange={(e) => {
                                          handleChangeVariable(
                                            selectElem?.categoryAtt
                                              ?.attributeMasterId,
                                            e,
                                            i
                                          );
                                        }}
                                        dropdown={true}
                                        option={selectElem?.attValue.map(
                                          (opElem) => {
                                            return {
                                              code: opElem.attributeId,
                                              name: opElem.name,
                                              attributeType:
                                                selectElem?.categoryAtt
                                                  ?.attributeType,
                                            };
                                          }
                                        )}
                                        search={(e) =>
                                          setFilterDrop((prev) => {
                                            return {
                                              ...prev,
                                              categoryAttributeMapFilter:
                                                e.target.value,
                                            };
                                          })
                                        }
                                        showClear={true}
                                        // customClear={false}
                                        clearDropdown={clearDropdown}
                                      />
                                      {selectElem?.categoryAtt
                                        ?.attributeType === 3 && (
                                        <Button
                                          icon="pi pi-cloud-upload"
                                          severity="warning"
                                          type="button"
                                          aria-label="Notification"
                                          className="mt-4 "
                                          tooltip="Image Upload"
                                          onClick={() => {
                                            handleOpenAttributeImagePopup({
                                              attributeMasterId:
                                                selectElem?.categoryAtt
                                                  ?.attributeMasterId,
                                              index: i,
                                            });
                                          }}
                                          tooltipOptions={{
                                            position: "bottom",
                                          }}
                                        />
                                      )}
                                    </Col>
                                  );
                                })}
                                <Col md="4 mb-4">
                                  <FormDataFeild
                                    label={TextType}
                                    name="taxType"
                                    id="taxType"
                                    placeholder={TextType}
                                    value={elem.taxType}
                                    handleChange={(e) =>
                                      handleChangeVariable(i, e)
                                    }
                                    dropdown={true}
                                    option={TEXT_TYPE}
                                    filter={false}
                                  />
                                </Col>
                                <Col md="3 mb-4">
                                  <FormDataFeild
                                    label={MRP}
                                    name="mrp"
                                    id="mrp"
                                    type={TYPE_FELDS.number}
                                    placeholder={MRP}
                                    value={elem.mrp}
                                    handleChange={(e) =>
                                      handleChangeVariable(i, e)
                                    }
                                  />
                                </Col>
                                <Col md="3 mb-4">
                                  <FormDataFeild
                                    label={"Sell Price *"}
                                    name="sellPrice"
                                    id="sellPrice"
                                    type={TYPE_FELDS.number}
                                    placeholder={"Sell Price *"}
                                    value={elem.sellPrice}
                                    handleChange={(e) =>
                                      handleChangeVariable(i, e)
                                    }
                                  />
                                </Col>
                                <Col md="3 mb-4">
                                  <FormDataFeild
                                    label={TAX_PER}
                                    name="taxPer"
                                    id="taxPer"
                                    type={TYPE_FELDS.number}
                                    placeholder={TAX_PER}
                                    value={elem.taxPer}
                                    handleChange={(e) =>
                                      handleChangeVariable(i, e)
                                    }
                                  />
                                </Col>
                                <Col md="3 mb-4">
                                  <FormDataFeild
                                    label={"Tax Amount"}
                                    name="taxAmt"
                                    id="taxAmt"
                                    type={TYPE_FELDS.number}
                                    placeholder={"Tax Amount"}
                                    value={elem.taxAmt}
                                    handleChange={(e) =>
                                      handleChangeVariable(i, e)
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                      </Row>
                    )}
                    <Col md="12 mb-4">
                      <FormDataFeildTextArea
                        label={`${SortDescription} *`}
                        name="sortDesc"
                        id="sortDesc"
                        type={TYPE_FELDS.text}
                        placeholder={`${SortDescription} *`}
                        value={formData.sortDesc}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.sortDesc && (
                        <span className="error-validation">
                          {errors.sortDesc}
                        </span>
                      )}
                    </Col>
                    <Col md="12 mb-4">
                      <div className="flex flex-column gap-2 padding">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {`${LongDescription} *`}
                        </label>
                        <CKEditors
                          activeclassName="p10"
                          content={formData.longDesc}
                          events={{
                            change: onChange,
                          }}
                        />
                      </div>
                      {errors.longDesc && (
                        <span className="error-validation">
                          {errors.longDesc}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12 mb-4"
                      className="btn-style flex justify-content-between "
                    >
                      <Button
                        label={Close}
                        severity="danger"
                        icon="pi pi-times"
                        onClick={() => redirect()}
                      />
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createProductsLoader || updateProductsLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {open.attribute && <SelectAttributes />}
      {open.attributeImageUpload && (
        <AttributeImageUploadPopup
          openImageUploadModal={openImageUploadModal}
          isEdit={isEdit}
          handleChange={handleChange}
          handleRemove={handleRemove}
          handleAddMore={handleAddMore}
          attributeImages={attributeImages}
          setAttributeImages={setAttributeImages}
          attributeMasterId={attributeMasterId}
          attributeMasterIndex={attributeMasterIndex}
        />
      )}
      <CropImagePopup
        dialogModal={dialogModal}
        setdialogsModal={setdialogsModal}
        image={formData?.logo}
        // aspect={aspect}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        setFormData={setFormData}
        uploadImage={uploadImage}
      />
    </Fragment>
  );
};

export default ProductsCreateFormPopup;
